.chat-room-group-detail {
  width: 430px;
  max-height: 240px;
  overflow-x: hidden;
  overflow-y: auto;
  .ant-card {
    background-color: #FAFAFA;
    padding: 10px 0;
    border-radius: 6px;
    .ant-card-cover img {
      width: 64px;
      height: 64px;
      display: block;
      border-radius: 6px;
      background-color: #FFFFFF;
      margin: auto;
      user-select: none;
      pointer-events: none;
    }
    .ant-card-body {
      padding: 0;
      margin-top: 10px;
      .ant-card-meta {
        text-align: center;
        .ant-card-meta-title {
          margin-bottom: 0;
        }
      }
    }
  }
}
.chat-room-user-avatar {
  width: 32px;
  height: 32px;
  margin: auto;
  border-radius: 6px;
  color: #ffffff;
  text-align: center;
  line-height: 32px;
  font-size: 20px;
  user-select: none;
}
.chat-room-user-avatar.bigger {
  width: 64px;
  height: 64px;
  line-height: 64px;
  font-size: 28px;
}
.chat-room-user-avatar.agent {
  background-color: darkorange !important;
}
.chat-room-user-avatar.student {
  background-color: darkseagreen !important;
}
.chat-room-user-avatar.teacher {
  background-color: darkslateblue !important;
}
.preview-image-modal {
  width: 80% !important;
  height: 80% !important;
  .ant-modal-content {
    height: 100%;
    padding: 20px 0;
    .ant-modal-body {
      height: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
      display: flex;
      img {
        max-width: 100%;
        width: auto;
        margin: auto;
        object-fit: contain;
      }
    }
  }
}
.chat-room {
  position: fixed;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  z-index: 100;
  .no-chat {
    width: 500px;
    height: 400px;
    overflow: hidden;
    user-select: none;
    img {
      width: 310px;
      height: auto;
      display: block;
      margin: 110px auto 0;
      pointer-events: none;
    }
    p {
      text-align: center;
      color: #ccc;
      font-size: 20px;
      line-height: 40px;
    }
  }
  .collapse-header {
    width: 260px;
    height: 32px;
    line-height: 32px;
    font-size: 16px;
    padding-left: 10px;
    user-select: none;
    .icon {
      float: left;
      width: 30px;
      height: 30px;
      font-size: 26px;
      margin-top: 2px;
      margin-left: -40px;
      transition: 0.4s all ease-in;
      pointer-events: none;
    }
  }
  .group-list.expire-tip-space {
    padding-top: 88px;
    cursor: default;
    .switch-expire {
      margin-top: -88px;
    }
  }
  .group-list {
    position: absolute;
    top: 59px;
    right: -20px;
    height: 543px;
    max-height: calc(90vh - 60px);
    width: 320px;
    padding-top: 68px;
    padding-bottom: 40px;
    padding-right: 20px;
    background: #FAFAF5;
    border-left: 1px solid #d9d9d9;
    overflow-x: hidden;
    overflow-y: scroll;
    .switch-expire {
      position: fixed;
      margin-top: -68px;
      width: 300px;
      height: 68px;
      z-index: 1;
      background-color: #E0E0E0;
      user-select: none;
      .ant-radio-button-wrapper-checked {
        box-shadow: none;
        span {
          text-align: center;
        }
        &:before {
          opacity: 0;
        }
        &:hover {
          color: #1890ff;
          background-color: #FFFFFF;
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          left: 0; right: 0;
          width: 50%;
          height: 2px;
          background-color: #1890ff;
          margin: 0 auto;
        }
        color: #1890ff;
        background-color: #FFFFFF;
      }
      .expire-tip {
        width: 100%;
        background-color: rgba(24, 144, 255, 0.5);
        color: #ffffff;
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        white-space: nowrap;
      }
      .type-tab-identity {
        width: 100%;
        margin: 1px 0;
        .ant-radio-button-wrapper {
          border: none;
          border-radius: 0;
          width: 50%;
          text-align: center;
        }
      }
    }
    .type-tab-expire {
      border-bottom: 1px solid #E0E0E0;
      background-color: #FFFFFF;
      width: 300px;
      height: 34px;
      display: flex;
      p {
        width: 50%;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #FFFFFF;
        &.active {
          span {
            background-color: #1890ff;
          }
        }
        span {
          background-color: #d8d7dd;
          font-size: 12px;
          padding: 0 14px;
          border-radius: 999px;
          margin: 0 auto;
        }
      }
    }
    .group-item-view {
      width: inherit;
      .group-item {
        &.group-item-expire {
          background: #e2e2e2;
        }
        &.group-item-active {
          background: #e6f8ff;
        }
        width: 100%;
        border-bottom: 1px solid #E0E0E0;
        margin-bottom: 1px;
        background: #FFFFFF;
        cursor: pointer;
        user-select: none;
        .group-count {
          font-size: 16px;
          color: #2e2e2e;
          margin-top: -10px;
          font-weight: 500;
          margin-right: 10px;
        }
        .moment-from-now {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.3);
        }
        .word {
          display: inline;
          * {
            white-space: nowrap !important;
            display: inline !important;
            background-color: transparent !important;
            font-size: 14px !important;
          }
          img {
            width: auto !important;
            height: 21px !important;
            background-color: #ffffff !important;
          }
        }
      }
    }
    .tip {
      text-align: center;
      padding: 0 20px;
      width: 100%;
      /* display: block; */
      margin-top: 20px;
    }
  }
  .input-area.masking-layer:after {
    content: "This course is already finished,nothing to say here!";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0,0,0,0.2);
    text-align: center;
    line-height: 200px;
    font-size: 18px;
    color: #FFFFFF;
    text-shadow: -1px -1px white, 1px 1px #333;
  }
  .input-area {
    position: absolute;
    width: 501px;
    height: 200px;
    bottom: 0;
    left: 1px;
    overflow: hidden;
    .toolbar {
      width: 100%;
      height: 50px;
      background: #f9f9f9;
      box-shadow: 0 3px 6px rgba(0,0,0,0.04);
      position: absolute;
      top: 0;
      user-select: none;
      .tool-item {
        width: 50px;
        height: 50px;
        cursor: pointer;
        position: relative;
        img {
          padding: 13px;
          width: 50px;
          height: 50px;
          display: block;
        }
        img:hover {
          background-color: rgba(0, 0, 0, 0.08);
        }
        .upload-progress {
          position: absolute;
          width: 50px;
          height: 50px;
          top: 0;
          left: 0;
          background: rgba(0,0,0,0.14);
          border-radius: 50%;
          cursor: pointer;
          span {
            color: #FFFFFF;
            font-weight: 800;
            font-size: 12px;
          }
        }
      }
    }
    .input-area-view {
      margin-top: 50px;
      z-index: -1;
      width: 100%;
      height: 150px;
      padding: 10px;
      [contenteditable=true] {
        width: 110%;
        padding-right: 10%;
        max-height: 90px;
        word-break: break-word;
        font-size: 14px;
        color: #666;
        overflow-x: hidden;
        overflow-y: scroll;
        white-space: pre-wrap;
        position: relative;
      }
      .btn-view {
        width: 100%;
        height: 32px;
        position: absolute;
        left: 0;
        bottom: 10px;
        button {
          float: right;
          margin-right: 10px;
        }
        .close-time {
          height: 32px;
          line-height: 32px;
          color: red;
          margin-left: 10px;
        }
      }
    }
  }
  .chat-view {
    position: absolute;
    width: 501px;
    //height: 400px;
    top: 0;
    left: 1px;
    overflow: hidden;
    .group-title {
      width: 100%;
      height: 58px;
      padding: 0 15px;
      background-color: #FFF;
      border-top: 2px solid #d9d9d9;
      overflow: hidden;
      box-shadow: 0 3px 6px rgba(0,0,0,0.04);
      cursor: default;
      .group-detail-icon {
        width: 56px;
        height: 56px;
        cursor: pointer;
        img {
          margin: 6px;
          width: 44px;
          height: 44px;
          padding: 8px;
          display: block;
          border-radius: 50%;
        }
        img:hover {
          background-color: rgba(0, 0, 0, 0.08);
        }
      }
      .group-name {
        float: left;
        width: 80%;
        font-size: 18px;
        line-height: 56px;
      }
    }
    .message-window {
      width: 601px;
      height: 340px;
      max-height: calc(90vh - 260px);
      padding: 20px 115px 10px 15px;
      overflow-x: hidden;
      overflow-y: scroll;
      white-space: pre-wrap;
      .message-item {
        overflow: hidden;
        .message-translate {
          border-top: 1px solid #cccccc;
          margin-top: 5px;
          padding-top: 5px;
          font-size: 14px;
          color: #666666;
          background-color: #eeeeee;
          border-radius: 0 0 8px 8px;
          span {
            display: block;
            text-align: right;
            cursor: pointer;
            font-size: 12px;
            color: #999999;
            border-top: 1px dashed #cccccc;
            margin-top: 5px;
            padding-top: 5px;
            u {
              float: left;
              padding-right: 20px;
            }
            i {
              margin-right: 5px;
            }
          }
        }
        .ant-comment {
          width: 90%;
          .ant-comment-inner {
            padding: 10px 0;
            .ant-comment-content-detail {
              float: left;
              background-color: #FFF;
              word-break: break-word;
              border-radius: 8px;
              .message-view-item {
                position: relative;
                .image {
                  cursor: pointer;
                  user-select: none;
                }
                .image:hover:before {
                  content: "";
                  display: block;
                  position: absolute;
                  top: 0; left: 0;
                  width: 100%;
                  height: 100%;
                  border-radius: 8px;
                  background-color: rgba(255, 255, 255, 0.5);
                  z-index: 1;
                }
                .image:hover:after {
                  content: "";
                  display: block;
                  position: absolute;
                  top: 0; left: 0;
                  bottom: 0; right: 0;
                  width: 40px;
                  height: 40px;
                  max-width: 50%;
                  max-height: 50%;
                  margin: auto;
                  background: url("../../assets/images/openImage.png") center no-repeat;
                  background-size: contain;
                  z-index: 2;
                }
                img {
                  position: relative;
                  width: auto;
                  height: 200px;
                  max-width: 350px;
                  max-height: 200px;
                  border-radius: 8px;
                  overflow: hidden;
                  background-color: #ffffff;
                  z-index: 0;
                  pointer-events: none;
                }
                .file-link {
                  width: 260px;
                  height: 60px;
                  background-color: #ffffff;
                  display: block;
                  box-shadow: -3px 3px 10px rgba(0,0,0,0.1);
                  border-radius: 4px;
                  overflow: hidden;
                  user-select: none;
                  i {
                    display: block;
                    float: left;
                    font-size: 40px;
                    padding: 10px;
                    box-shadow: 2px 0 10px rgba(0,0,0,0.1);
                  }
                  b {
                    margin-left: 14px;
                    font-size: 12px;
                  }
                  span {
                    word-break: break-word;
                    display: block;
                    padding-left: 14px;
                    width: 185px;
                    font-size: 14px;
                    line-height: 1.2em;
                    color: #666666;
                    overflow : hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                  }
                }
                .word {
                  padding: 5px 10px;
                }
              }
            }
          }
        }
      }
      .my-message {
        .ant-comment {
          float: right;
          .ant-comment-inner {
            flex-direction: row-reverse;
            .ant-comment-avatar {
              margin-right: 0;
              margin-left: 12px;
            }
            .ant-comment-content-detail {
              float: right;
              background-color: #cde6fc;
            }
          }
        }
      }
      .sys-msg {
        width: 100%;
        padding: 2px 90px 0;
        text-align: center;
        cursor: default;
        span {
          background-color: rgba(0, 0, 0, 0.15);
          padding: 2px 15px;
          border-radius: 8px;
          display: inline-block;
          font-size: 12px;
          color: #fff;
          word-break: break-word;
          text-align: justify;
        }
      }
    }
    .unread-message {
      width: 100%;
      position: absolute;
      bottom: 0;
      margin: 10px 0;
      text-align: center;
      span {
        font-size: 12px;
        background: #51c418;
        color: #ffffff;
        padding: 2px 15px;
        border-radius: 8px;
        cursor: pointer;
        &:before {
          content: '';
          width: 10px;
          height: 10px;
          display: inline-block;
          background: url("../../assets/images/down-icon.png") center no-repeat;
          background-size: cover;
          margin-right: 5px;
        }
      }
    }
  }
  .word-img {
    display: inline;
    width: auto;
    height: 30px !important;
    padding: 0 5px;
  }
  .ant-comment-avatar img {
    border-radius: 6px;
    background-color: #ffffff;
    pointer-events: none;
    user-select: none;
  }
  .ant-avatar-lg {
    border-radius: 6px;
    pointer-events: none;
  }
  .ant-collapse-item-active {
    box-shadow: -5px 4px 10px #929292;
    .ant-collapse-header {
      top: 0;
      border-radius: 0 !important;
      .anticon-right svg {
        transform: rotate(90deg) !important;
      }
    }
  }
  .ant-collapse-header {
    .anticon-right svg {
      transform: rotate(-90deg) !important;
    }
    width: 300px;
    position: absolute !important;
    top: -60px;
    right: 0;
    background: #FFFFFF;
    border-top: 2px solid #d9d9d9;
    border-left: 1px solid #d9d9d9;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
    border-radius: 4px 0 0 4px !important;
    transition: none !important;
  }
  .ant-collapse-content {
    width: 800px;
    height: 600px;
    max-height: 90vh;
    .ant-collapse-content-box {
      padding: 0;
    }
  }
  .ant-collapse .ant-collapse-item .ant-collapse-header .anticon {
    left: initial;
    right: 16px;
  }
}
.chat-message-contextMenu {
  button {
    border-color: transparent;
  }
}
@nav-bg-color: #f6f7fa;@border-color: #e6e6e6;@active-color: #1890ff;