*{
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  outline: none;
  padding: 0;
  margin: 0;
}
html{
  height: 100%;
}
body{
  font-size: 14px;
  color: #000;
  background: #efefef;
  height: 100%;
  font-family: PingFang-SC-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ul li{
  list-style: none;
}
h1, h2, h3, h4 ,h5 {
  margin: 0;
}
h1{
  font-size: 16px;
}
h2{
  font-size: 14px;
}
a{
  color: #333333;
  text-decoration: none;
  border: none;
  outline: 0;
}
a:link,a:visited,a:hover,a:active{
  text-decoration:none;
}
a:focus{
  outline:0;
}
button, a{
  outline: none;
}
input{
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  display: block;
}
img {
  width: 100%;
  display: block;
}
p {
  margin: 0;
}

/** 通用左右居中 **/
.lrcenter{
  left: 50%;
  transform: translate(-50%,0);
  -webkit-transform: translate(-50%,0);
  -o-transform: translate(-50%,0);
  -moz-transform: translate(-50%,0);
  -ms-transform: translate(-50%,0);
}

/** 通用上下居中 **/
.tbcenter{
  top: 50%;
  transform: translate(0,-50%);
  -webkit-transform: translate(0,-50%);
  -o-transform: translate(0,-50%);
  -moz-transform: translate(0,-50%);
  -ms-transform: translate(0,-50%);
}

/** 三角形 **/
.triangle-up:before {
  width: 0;
  height: 0;
  content: '';
  display: inline-block;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 5px solid #000c17;
  vertical-align: middle;
  margin: -3px 8px 0 0;
}
.triangle-down:before, .triangle-down-after:after {
  width: 0;
  height: 0;
  content: '';
  display: inline-block;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 5px solid #000c17;
  vertical-align: middle;
  margin: -3px 8px 0 0;
}

/** 通用上下左右居中 **/
.tblrcenter{
  left:50%;
  top:50%;
  transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  -o-transform: translate(-50%,-50%);
  -moz-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
}

.clearBoth{
    clear: both;
}

.threeDot{
    white-space:nowrap;
    text-overflow:ellipsis;
    -o-text-overflow:ellipsis;
    overflow:hidden;
}
.left {
    float: left;
}
.right {
    float: right;
}

input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #c3c3c3 !important;
}
input::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #c3c3c3 !important;
}
input:-ms-input-placeholder { /* Internet Explorer 10+ */
    color: #c3c3c3 !important;
}
input::-webkit-input-placeholder { /* WebKit browsers */
    color: #c3c3c3 !important;
}

textarea {
  resize: none;
}

[contenteditable=true]:empty:before{
  content: attr(placeholder);
  display: block; /* For Firefox */
  color: #d6cee2;
}

.overflow-hidden {
  overflow: hidden;
}

#root {
  height: 100%;
}

.pagination-box {
  margin-top: 30px;
  overflow: hidden;
  line-height: 32px;
  font-size: 16px;
}

.tips {
  text-align: center;
  font-size: 14px;
  line-height: 30px;
  color: #999;
}

.gray {
  filter: grayscale(1);
  transition: 0.4s all ease-out;
}

.none {
  display: none;
}

.ellipsis {
  width: 94%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@nav-bg-color: #f6f7fa;@border-color: #e6e6e6;@active-color: #1890ff;