.App {
  height: 100%;
  position: relative;
  .right-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    .page {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 0;
      .page-content {
        background-color: #fff;
        margin: 15px;
        flex: 1;
        overflow: auto;
      }
    }
  }
  .header-container {
    height: 51px;
    padding: 0 15px;
     background-color: #fff;
     border-bottom: 1px solid @border-color;
    .page-title {
      font-size: 25px;
      color: #000;
      font-weight: bold;
      line-height: 51px;
    }
    .userInfo {
      line-height: 35px;
      padding: 10px 0;
      height: 100%;
      cursor: pointer;
      span {
        height: 100%;
        display: inline-block;
        &.ant-select-selection__clear {
          background-color: transparent;
        }
      }
    }
  }
  .fullPage {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: #fff;
  }
  .language {
    color: #000;
    
    span {
      opacity: 0.4;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
      &.active {
        opacity: 1;
        cursor: auto;
      }
    }
  }
}

.menu-container {
  .logo {
    background-color: #00284D;
    padding: 10px 24px;
    img {
      max-width: 152px;
      height: 30px;
      object-fit: contain;
    }
  }
  &.collapsed {
    width: 80px;
    .logo img {
      width: 35px;
    }
  }
  .sider-trigger {
    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: rgb(0, 33, 64);
    cursor: pointer;
    button {
      margin: 0 auto;
      display: block;
      background-color: rgb(0, 33, 64);
      border-color: rgb(0, 33, 64);
    }
  }
}

.ant-message {
  text-align: center;
}
// 订单查看弹窗
.viewOrderDetail {
  line-height: 25px;
  .label {
      text-align: right;
  }
}

.ant-tooltip {
  .ant-tooltip-inner {
    padding: 0;
  }
}

.ant-tag-blue:hover {
  opacity: 1 !important;
}
.ant-time-picker-panel-select {
  max-height: 224px !important;
}

.App.Whetstone {

  .ant-menu.ant-menu-dark .ant-menu-item-selected {
    background-color: #3A91A4;
  }

  .ant-breadcrumb-link a, .teacherDetail-header div {
    color: #3A91A4 !important;
  }

  .ant-btn-primary,
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background-color: #3A91A4;
    border-color: #3A91A4;
    color: #fff !important;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #3A91A4 !important;
  }

  .ant-input:focus,
  .ant-input:hover,
  .ant-select-selection:hover,
  .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled),
  .ant-btn:hover, .ant-btn:focus,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-color: #3A91A4;
  }

  .ant-btn:hover, .ant-btn:focus,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #3A91A4;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    box-shadow: -1px 0 0 0 #3A91A4;
  }
}
@nav-bg-color: #f6f7fa;@border-color: #e6e6e6;@active-color: #1890ff;