.tx {
  font-size: inherit;
  width: 100%;
  height: 100%;
  user-select: none;
  pointer-events: none;
  background-color: rgba(0,0,0,0.08);
  overflow: hidden;
  border-radius: 10%;
  .avatar {
    display: block;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 10%;
    background-color: #ffffff;
    line-height: 1.2em;
    width: 32%;
    height: 32%;
    margin: 1% 0 0 1%;
    float: left;
    font-size: calc( 1em * 0.32 * 0.8 );
  }
  .avatar:first-child:nth-last-child(1) {
    width: 100%;
    height: 100%;
    margin: 0;
    font-size: calc( 1em * 0.8 );
  }
  .avatar:first-child:nth-last-child(2),
  .avatar:first-child:nth-last-child(2) ~.avatar {
    width: 48%;
    height: 48%;
    margin: 26% 1%;
    float: left;
    font-size: calc( 1em * 0.48 * 0.8 );
  }
  .avatar:first-child:nth-last-child(3) {
    width: 48%;
    height: 48%;
    margin: 1% 26% 0;
    font-size: calc( 1em * 0.48 * 0.8 );
  }
  .avatar:first-child:nth-last-child(3) ~.avatar {
    width: 48%;
    height: 48%;
    margin: 1%;
    float: left;
    font-size: calc( 1em * 0.48 * 0.8 );
  }
  .avatar:first-child:nth-last-child(4),
  .avatar:first-child:nth-last-child(4) ~.avatar {
    width: 48%;
    height: 48%;
    margin: 1%;
    float: left;
    font-size: calc( 1em * 0.48 * 0.8 );
  }
  .avatar:first-child:nth-last-child(5) {
    width: 32%;
    height: 32%;
    margin-top: 18%;
    margin-left: 18%;
    float: left;
    font-size: calc( 1em * 0.32 * 0.8 );
  }
  .avatar:nth-child(2):nth-last-child(4) {
    width: 32%;
    height: 32%;
    margin-top: 18%;
    margin-left: 1%;
    float: left;
    font-size: calc( 1em * 0.32 * 0.8 );
  }
  .avatar:nth-child(2):nth-last-child(4) ~.avatar {
    width: 32%;
    height: 32%;
    margin-top: 1%;
    margin-left: 1%;
    float: left;
    font-size: calc( 1em * 0.32 * 0.8 );
  }
  .avatar:first-child:nth-last-child(6),
  .avatar:nth-child(2):nth-last-child(5),
  .avatar:nth-child(3):nth-last-child(4) {
    width: 32%;
    height: 32%;
    margin-top: 18%;
    margin-left: 1%;
    float: left;
    font-size: calc( 1em * 0.32 * 0.8 );
  }
  .avatar:nth-child(3):nth-last-child(4) ~.avatar {
    width: 32%;
    height: 32%;
    margin-top: 1%;
    margin-left: 1%;
    float: left;
    font-size: calc( 1em * 0.32 * 0.8 );
  }
  .avatar:first-child:nth-last-child(7) {
    width: 32%;
    height: 32%;
    margin-top: 1%;
    margin-left: 34%;
    font-size: calc( 1em * 0.32 * 0.8 );
  }
  .avatar:first-child:nth-last-child(7) ~.avatar {
    width: 32%;
    height: 32%;
    margin-top: 1%;
    margin-left: 1%;
    float: left;
    font-size: calc( 1em * 0.32 * 0.8 );
  }
  .avatar:first-child:nth-last-child(8) {
    width: 32%;
    height: 32%;
    margin-top: 1%;
    margin-left: 18%;
    float: left;
  }
  .avatar:nth-child(2):nth-last-child(7),
  .avatar:nth-child(2):nth-last-child(7) ~.avatar {
    width: 32%;
    height: 32%;
    margin-top: 1%;
    margin-left: 1%;
    float: left;
    font-size: calc( 1em * 0.32 * 0.8 );
  }
}
@nav-bg-color: #f6f7fa;@border-color: #e6e6e6;@active-color: #1890ff;