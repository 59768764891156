.addStudent-intl-tel-input-container {
    position: relative;
    display: inline-block;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    width: 100%;
    .country-list {
        margin-top: 13px;
        margin-left: -2px;
        border-radius: 4px;
    }
    .intl-tel-input {
        width: 90%;
    }
    .intl-tel-input-text {
        width: 100%;
    }
}
@nav-bg-color: #f6f7fa;@border-color: #e6e6e6;@active-color: #1890ff;